import React from 'react';

import * as Icon from 'react-feather';

import Header from '../components/header';
import Layout from '../components/layout';
import Seo from '../components/seo';

const SolcellerPage = () => {
    return (
        <Layout>
            <Seo title="Solceller" />
            <Header image={true} big={true} text="Ta steget till solenergi" />

            <section className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <h3>25 års effektgaranti</h3>
                            <p className="lh-base">För din trygghet så erbjuder vi en komplett installation av dina solceller och endast produkter med lång garanti.</p>
                        </div>
                        <div className="col-12 col-lg-4 mt-5 mt-lg-0">
                            <h3>95% kundnöjdhet</h3>
                            <p className="lh-base">Vi är stolta över att kunna erbjuda både produkter och solcellsinstallationer av hög kvalitet, vilket har gett oss flest 5-stjärniga omdömen.</p>
                        </div>
                        <div className="col-12 col-lg-4 mt-5 mt-lg-0">
                            <h3>Flexibel finansiering</h3>
                            <p className="lh-base">För att underlätta för dig så erbjuder vi flera olika betalningsmetoder. Välj vilken avbetalningsplan som funkar bäst för dig - upp till 20 år.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="content" style={{ backgroundColor: '#F7F7F7' }}>
                <div className="container">
                    <div className="row pt-0">
                        <div className="col-lg-6 d-flex align-items-center">
                            <div className="p-3 p-lg-5">
                                <p className="lh-base mb-1">VI INSTALLERAR SOLCELLER ÖVER HELA SVERIGE</p>
                                <h2>Vi erbjuder solpaneler av hög kvalitet och banbrytande teknik.</h2>
                                <p className="lh-base mt-5">Vi gör det möjligt att installera och köpa solceller oavsett vart du bor, så att du kan producera el som är hållbart.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex align-items-center">
                            <img src="https://cdn.sanity.io/images/tn9ake74/production/0a151f5c473fa691aa62099e77c33d1afed7b28f-576x336.png?fm=webp" alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="content">
                <div className="container">
                    <div className="row pt-0 pb-0">
                        <div className="col">
                            <ul class="timeline">
                                <li>
                                    <div class="timeline-badge warning"><Icon.Package /></div>
                                    <div class="timeline-panel">
                                        <div class="timeline-heading">
                                            <h4 class="timeline-title">1. Välj din lösning</h4>
                                        </div>
                                        <div class="timeline-body">
                                            <p>Testa vår solcellskalkylator och se hur mycket du kan spara genom att installera solpaneler, eller kontakta en av våra experter med hjälp av detta formulär.</p>
                                        </div>
                                    </div>
                                </li>
                                <li class="timeline-inverted">
                                    <div class="timeline-badge warning"><Icon.Smile /></div>
                                    <div class="timeline-panel">
                                        <div class="timeline-heading">
                                            <h4 class="timeline-title">2. Rådgivning</h4>
                                        </div>
                                        <div class="timeline-body">
                                            <p>Du kommer att få en offert från en av våra experter som sedan går igenom förberedelserna inför installationen.</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="timeline-badge warning"><Icon.Home /></div>
                                    <div class="timeline-panel">
                                        <div class="timeline-heading">
                                            <h4 class="timeline-title">3. Installation</h4>
                                        </div>
                                        <div class="timeline-body">
                                            <p>För att göra det så enkelt och säkert som möjligt tar vi hand om hela processen, från design till installation.</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default SolcellerPage;